import './Privatlivspolitik.css';


function Privatlivspolitik() {
    return (
        <div className='pripolContainer'>
        <p className='privatlivspolitikHeader overskrift'>Cookie- og privatlivspolitik</p>
        <p className='pripolSubHeader overskrift'>Dataansvarlig</p>
        <p className='pripolText'>Vi er dataansvarlig for behandlingen af de personoplysninger, som vi behandler om vores kunder og 
        samarbejdspartnere. Du finder vores kontaktoplysninger nedenfor.</p>
        <p className='pripolText'>10:30 Visuel Kommunikation</p>
        <p className='pripolText'>Sanderumvej 16B, 5250 Odense SV</p>
        <p className='pripolText'>CVR-nr.: 29532125</p>
        <p className='pripolText'>Hvis du har spørgsmål til behandlingen af dine personoplysninger, så kan du kontakte os via kontakt@1030.dk.</p>
        <p className='pripolSubHeader overskrift'>Behandlingsaktiviteter</p>
        <p className='pripolText'>Som dataansvarlig jf. GDPR, så har vi følgende behandlingsaktiviteter.</p>

        <p className='pripolSubSubHeader overskrift'>Besøg på hjemmeside</p>
        <p className='pripolText'>Når du besøger vores hjemmeside, så anvender vi en enkelt cookie, for at hjemmesiden kan huske om du allerede har 
        klikket "OK" på cookie-informationsbanneret. Det er muligt at slette eller blokere for cookies. Se vejledning: <a className='pripolLinks' href="https://minecookies.org/cookiehandtering/" target="_blank" rel="noopener noreferrer">https://minecookies.org/cookiehandtering/</a></p>

        <p className='pripolSubSubHeader overskrift'>Kommunikation med potentielle kunder</p>
        <p className='pripolText'>Hvis du har spørgsmål til vores side, eller ønsker at høre mere om vores services, så kan du kontakte os via:</p>
        <ul>
            <li>Telefon: 8813 1030</li>
            <li>Email: kontakt@1030.dk</li>
        </ul>
        <p className='pripolText'>Herigennem vil vi behandle dine personoplysninger, så vi kan indgå i en dialog med dig - fx svare på spørgsmål 
        om vores ydelser. Vi behandler kun den information, som du giver os i forbindelse med vores kommunikation.</p>
        <p className='pripolText'>Vi vil typisk behandle følgende almindelige oplysninger: navn, email, telefonnummer.</p>
        <p className='pripolText'>Vores hjemmel til at behandle disse personoplysninger er databeskyttelsesforordningens artikel 6, stk. 1 litra f.</p>

        <p className='pripolSubSubHeader overskrift'>Kunder</p>
        <p className='pripolText'>Vi har behov for at kommunikere med vores kunder, så vi sikrer os, at ydelsen leveres korrekt. Herigennem kan vi behandle oplysninger om navn, 
        adresse, ydelser, særlige aftaler, betalingsinformationer og lignende. </p>
        <p className='pripolText'>Hjemlen til at behandle disse personoplysningerer er databeskyttelsesforordningens artikel 6, stk. 1 litra b.</p>

        <p className='pripolSubSubHeader overskrift'>Periode for opbevaring</p>
        <p className='pripolText'>Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til lovgivningen, og vi sletter dem, når de ikke længere er 
        nødvendige. Perioden afhænger af karakteren af oplysningen og baggrunden for opbevaring. Det er derfor ikke muligt at angive en generel tidsramme for, 
        hvornår informationer slettes.</p>

        <p className='pripolSubSubHeader overskrift'>Bogføring</p>
        <p className='pripolText'>Vi skal gemme alle regnskabsbilag jf. bogføringsloven. Det betyder, at vi gemmer fakturaer og lignende bilag til brug for 
        regnskabsføring. Heraf kan der fremgå almindelige personoplysninger som navn, adresse, ydelsesbeskrivelse.</p>
        <p className='pripolText'>Vores hjemmel til at behandle personoplysninger til bogføringen er databeskyttelsesforordningens artikel 6, stk.1 litra.</p>
        <p className='pripolText'>Vi opbevarer disse oplysninger i minimum 5 år efter at indeværende regnskabsår er afsluttet. </p>

        <p className='pripolSubSubHeader overskrift'>Jobansøgninger</p>
        <p className='pripolText'>Vi tager glædeligt imod jobansøgninger med henblik på at vurdere om de matcher et ansættelsesbehov i vores virksomhed. </p>
        <p className='pripolText'>Hvis du sender din jobansøgning til os, så er vores hjemmel til at behandle dine personoplysninger databeskyttelsesforordningens 
        artikel 6, stk. 1 litra f. </p>
        <p className='pripolText'>Hvis du har sendt en uopfordret ansøgning, så vil vi vurdere om din ansøgning er relevant, og herefter slette 
        dine oplysninger igen, hvis der ikke er et match. </p>
        <p className='pripolText'>Hvis du har sendt en ansøgning til et opslået job, så vil vi bortskaffe din ansøgning i det tilfælde, at du ikke ansættes, 
        og umiddelbart efter at den rette kandidat er fundet til jobbet.</p>
        <p className='pripolText'>Hvis du indgår i et rekrutteringsforløb og/eller ansættes til jobbet, så vil vi give dig særskilt information om 
        hvordan, at vi behandler dine personoplysninger i denne forbindelse. </p>

        <p className='pripolSubHeader overskrift'>Databehandlere</p>
        <p className='pripolText'>Få kan klare alt selv, og det samme gælder os. Vi har derfor samarbejdspartnere, samt benytter os af leverandører, 
        hvoraf nogle kan være databehandlere.</p>
        <p className='pripolText'>Eksterne leverandører kan eksempelvis levere systemer til at organisere vores arbejde, services, rådgivning, IT-hosting eller 
        markedsføring.</p>
        <ul className='pripolUl'>
            <li className='pripolLi'><p className='pripolText'>Visma | e-conomic - Regnskabs- og bogføringsprogram</p></li>
            <li className='pripolLi'><p className='pripolText'>efacto - Faktureringsprogram</p></li>
            <li className='pripolLi'><p className='pripolText'>One.com - Webhosting</p></li>
        </ul>
        <p className='pripolText'>Det er vores ansvar at sikre, at dine personoplysninger behandles ordentligt. Derfor stiller vi høje krav til vores 
        samarbejdspartnere, og vores partnere skal garantere, at dine personoplysninger er beskyttet.</p>
        <p className='pripolText'>Vi indgår derfor aftaler herom med virksomheder (databehandlere), der håndterer personoplysninger på vores 
        vegne for at højne sikkerheden af dine personoplysninger.</p>

        <p className='pripolSubHeader overskrift'>Videregivelse af personoplysninger</p>
        <p className='pripolText'>Vi videregiver ikke dine personoplysninger til tredjemand.</p>

        <p className='pripolSubHeader overskrift'>Profilering og automatiserede afgørelser</p>
        <p className='pripolText'>Vi foretager ikke profilering eller automatiserede afgørelser.</p>

        <p className='pripolSubHeader overskrift'>Tredjelandeoverførsler</p>
        <p className='pripolText'>Vi benytter som udgangspunkt databehandlere i EU/EØS, eller som opbevarer data i EU/EØS. </p>
        <p className='pripolText'>I nogle tilfælde er dette ikke muligt, og her kan der benyttes databehandlere udenfor EU/EØS, hvis disse kan give dine 
        personoplysninger en passende beskyttelse.</p>

        <p className='pripolSubHeader overskrift'>Behandlingssikkerhed</p>
        <p className='pripolText'>Vi holder behandlingen af personoplysninger sikker ved at have indført passende tekniske og organisatoriske foranstaltninger. </p>
        <p className='pripolText'>Vi har lavet risikovurderinger af vores behandling af personoplysninger, og har herefter indført passende tekniske og organisatoriske 
        foranstaltninger for at øge behandlingssikkerheden.</p>
        <p className='pripolText'>En af vores vigtigste foranstaltninger er at holde vores medarbejdere opdaterede om GDPR via løbende awareness træning, GDPR kursus, 
        samt ved at gennemgå vores GDPR-procedurer med medarbejderne. </p>

        <p className='pripolSubHeader overskrift'>De registreredes rettigheder</p>
        <p className='pripolText'>Du har efter databeskyttelsesforordningen en række rettigheder i forhold til vores behandling af oplysninger om dig.</p>
        <p className='pripolText'>Hvis du vil gøre brug af dine rettigheder skal du kontakte os, så vi kan hjælpe dig med dette.</p>

        <p className='pripolSubSubHeader overskrift'>Ret til at se oplysninger (indsigtsret)</p>
        <p className='pripolText'>Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.</p>

        <p className='pripolSubSubHeader overskrift'>Ret til berigtigelse (rettelse)</p>
        <p className='pripolText'>Du har ret til at få urigtige oplysninger om dig selv rettet.</p>

        <p className='pripolSubSubHeader overskrift'>Ret til sletning</p>
        <p className='pripolText'>I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden tidspunktet for vores almindelige generelle sletning indtræffer.</p>

        <p className='pripolSubSubHeader overskrift'>Ret til begrænsning af behandling</p>
        <p className='pripolText'>Du har i visse tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis du har ret til at få begrænset behandlingen, må vi 
        fremover kun behandle oplysningerne – bortset fra opbevaring – med dit samtykke, eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares, 
        eller for at beskytte en person eller vigtige samfundsinteresser.</p>

        <p className='pripolSubSubHeader overskrift'>Ret til indsigelse</p>
        <p className='pripolText'>Du har i visse tilfælde ret til at gøre indsigelse mod vores ellers lovlige behandling af dine personoplysninger. 
        Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte markedsføring.</p>

        <p className='pripolSubSubHeader overskrift'>Ret til at transmittere oplysninger (dataportabilitet)</p>
        <p className='pripolText'>Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret, almindeligt anvendt og maskinlæsbart format 
        samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden hindring.</p>
        <p className='pripolText'>Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder på www.datatilsynet.dk.</p>

        <p className='pripolSubSubHeader overskrift'>Tilbagetrækning af samtykke</p>
        <p className='pripolText'>Når vores behandling af dine personoplysninger er baseret på dit samtykke, så har du ret til at trække dit samtykke tilbage. </p>

        <p className='pripolSubSubHeader overskrift'>Klage til Datatilsynet</p>
        <p className='pripolText'>Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, 
        vi behandler dine personoplysninger på. Du finder Datatilsynets kontaktoplysninger på www.datatilsynet.dk.</p>
        <p className='pripolText'>Vi vil generelt opfordre dig til at læse mere om GDPR, så du er opdateret på reglerne.</p>
        <br />
        <br />
        <br />
        </div>
    )
}

export default Privatlivspolitik;