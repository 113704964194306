import './FlyoutMenu.css';
import { Link } from 'react-router-dom';
import Health from './Baggrund/health.png';
import Adfærd from './Baggrund/adfærd.png';
import Coprod from './Baggrund/coprod.png';
import Day2day from './Baggrund/day2day.png';
import Home from './Baggrund/home_left.png';
import Nature from './Baggrund/nature.png';
import OmOs from './Baggrund/omos.png';


function FlyoutMenu() {
    function handleNavOverlay() {
        let navOverlay = document.getElementById("flyoutNavOverlay");


        // if the navoverlay is currently open, close it, and vice versa
        if (navOverlay.style.height === "100%") {
            navOverlay.style.height = "0";
            navOverlay.style.width = "0";
            // also move the scrolling to only work on the menu items, not the content that is now hidden behind the nav overlay
            document.body.style.overflow = "visible";
            navOverlay.style.overflowY = "hidden";
        } else {
            navOverlay.style.height = "100%";
            navOverlay.style.width = "100%";
            document.body.style.overflow = "hidden";
            navOverlay.style.overflowY = "scroll";
        }

        // transform the menu icon to an X for closing
        document.getElementsByClassName("flyoutContainer")[0].classList.toggle("transformToX");
    }

    function showHoverImage(linkName) {
        let hoverimg = document.getElementById(linkName + "MenuHoverImg");
        hoverimg.style.opacity = "1";
    }

    function hideHoverImage(linkName) {
        let hoverimg = document.getElementById(linkName + "MenuHoverImg");
        hoverimg.style.opacity = "0";
    }

    return (
        <>
            <div id='flyoutMenuDiv' className='flyoutContainer' onClick={handleNavOverlay}>
                <div className='menuIconBar1'></div>
                <div className='menuIconBar2'></div>
                <div className='menuIconBar3'></div>
            </div>

            <div id="flyoutNavOverlay" className="navOverlay">

                <img id='healthMenuHoverImg' className='menuHoverImg' alt="" src={Health} />
                <img id='adfærdMenuHoverImg' className='menuHoverImg' alt="" src={Adfærd} />
                <img id='natureMenuHoverImg' className='menuHoverImg' alt="" src={Nature} />
                <img id='day2dayMenuHoverImg' className='menuHoverImg' alt="" src={Day2day} />
                <img id='coprodMenuHoverImg' className='menuHoverImg' alt="" src={Coprod} />
                <img id='ooMenuHoverImg' className='menuHoverImg' alt="" src={OmOs} />
                <img id='homeMenuHoverImg' className='menuHoverImg' alt="" src={Home} />

                <div className="navOptions">
                    <Link to="/health" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("health"); }} onMouseLeave={() => { hideHoverImage("health"); }}>HEALTH</Link>
                    <Link to="/adfaerdsdesign" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("adfærd"); }} onMouseLeave={() => { hideHoverImage("adfærd"); }}>ADFÆRDSDESIGN</Link>
                    <Link to="/nature" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("nature"); }} onMouseLeave={() => { hideHoverImage("nature"); }}>NATURE</Link>
                    <Link to="/day2day" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("day2day"); }} onMouseLeave={() => { hideHoverImage("day2day"); }}>DAY2DAY</Link>
                    <Link to="/coproduction" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("coprod"); }} onMouseLeave={() => { hideHoverImage("coprod"); }}>CO-PRODUCTION</Link>
                    <Link to="/omos" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("oo"); }} onMouseLeave={() => { hideHoverImage("oo"); }}>OM OS</Link>
                    <Link to="/" onClick={handleNavOverlay}
                        onMouseEnter={() => { showHoverImage("home"); }} onMouseLeave={() => { hideHoverImage("home"); }}>HOME</Link>
                </div>
            </div>
        </>
    )
}

export default FlyoutMenu;