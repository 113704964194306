import './Kunder.css';
import { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import cloos_advice from './Logoer/cloosadvice.png';
import cloos_regnskab from './Logoer/cloosregnskab.png';
import hg from './Logoer/hg.png';
import bornogsundhed from './Logoer/bornogsundhed.png';
import cogl from './Logoer/cogl.png';
import kollektivtrafik from './Logoer/kollektivtrafik.png';
import kombit from './Logoer/kombit.png';
import meremobil from './Logoer/meremobil.png';
import ouh from './Logoer/ouh.png';
import regnordjylland from './Logoer/regnordjylland.png';
import regsjaelland from './Logoer/regsjaelland.png';
import stolav from './Logoer/St.olav_logo.svg';
import rostgotland from './Logoer/RO_logo.svg';


function Kunder() {
    // sometimes Swiper pictures would not update their size when the window becomes maximized - so we make sure to update on every window resize, to solve that issue
    const kundeSwiperRef = useRef(null);
    useEffect(() => {
        window.onresize = () => {
            kundeSwiperRef.current.swiper.update();
        }

        // this return function is called when the component unmounts - ie when routing away from the Home page
        // we clear the onresize function, so we dont try to call this swiper while it doesnt exist
        return () => {
            window.onresize = () => { };
        }
    }, []);

    return (
        <div className='kundeWrapper'>
            <p className="kundeHeader overskrift transitionFontSize">KUNDER</p>

            <div className='carouselWrapper'>

                {/* NAVIGATION BUTTONS */}
                <div className="kundeCarouselPrev" onClick={(e) => { e.stopPropagation(); }}>
                    <div className='kundePrevBar1'></div>
                    <div className='kundePrevBar2'></div>
                </div>
                <div className="kundeCarouselNext" onClick={(e) => { e.stopPropagation(); }}>
                    <div className='kundeNextBar1'></div>
                    <div className='kundeNextBar2'></div>
                </div>

                <Swiper
                    ref={kundeSwiperRef}

                    // when the nav-arrows are clicked, call an extra slidechange, so the carousel slides 2 at a time
                    onSlideNextTransitionStart={(swiper) => {
                        if (window.innerWidth > 950) {  // only if the window is wide enough for there to be 3 logos; otherwise we just scroll one 
                            swiper.slideNext(500, false);   // false means no callbacks on this call, preventing infinite loop here
                        }
                    }}
                    onSlidePrevTransitionStart={(swiper) => {
                        if (window.innerWidth > 950) {
                            swiper.slidePrev(500, false);
                        }
                    }}

                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView={1}
                    speed={500}
                    loop={true}
                    navigation={{ prevEl: '.kundeCarouselPrev', nextEl: '.kundeCarouselNext' }}
                    breakpoints={{
                        // when window width >= 480px
                        480: { slidesPerView: 1, spaceBetween: 20 },
                        650: { slidesPerView: 2, spaceBetween: 25 },
                        950: { slidesPerView: 3, spaceBetween: 30 },
                        1400: { slidesPerView: 4, spaceBetween: 40 }
                    }}
                >
                    {/* den øverste vises faktisk til sidst, men ellers kommer de i denne rækkefølge: */}
                    <SwiperSlide className='kundeCarouselAligning'><img src={stolav} alt="St Olav logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={ouh} alt="OUH logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={meremobil} alt="Mere Mobil logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={bornogsundhed} alt="Børn og Sundhed logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={cogl} alt="Cunningham Lindsey logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={cloos_advice} alt="Cloos Advice logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={kombit} alt="KOMBIT logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={regsjaelland} alt="Region Sjælland logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={kollektivtrafik} alt="Kollektiv Trafik logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={hg} alt="Højmark Groth logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={regnordjylland} alt="Region Nordjylland logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={cloos_regnskab} alt="Cloos Regnskab logo" className='swiperKundeImages' /></SwiperSlide>
                    <SwiperSlide className='kundeCarouselAligning'><img src={rostgotland} alt="Region Östergötland logo" className='swiperKundeImages' /></SwiperSlide>
                </Swiper>
            </div>

            <div className="yderligereErfaringBaggrund">
                <p className="yderligereErfaringHeader overskrift transitionFontSize">Vi har også erfaring fra</p>
                <p className="yderligereErfaring transitionFontSize">
                    Alt Bogført | Agrosa Consult | BKH | Danfort | Desitek | Einar Engen Tandlægeklinik | Energi Invest Fyn |
                    Falbe-Hansens Fond | Fyns Psykolog Praksis | GreenOak | Headsetshop | Imagita | MathMagicians | Mobilsiden
                    | Officekonsulenterne | Racha Ayara | renz‐a | Rising Tide | Sanderumskolen | sundhedsplejerske.com | TC Anlæg
                    | TF Music
                </p>
            </div>
        </div>
    )
}

export default Kunder;