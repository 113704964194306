import { useEffect } from "react";
import { useLocation } from "react-router-dom";


function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);     // this is called every time pathname is updated; so every time we navigate on the site

    return null;    // this returns no visual element - is only used for resetting scroll on switching page
}

export default ScrollToTop;