import './LøsningerRude.css';
import { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import HCAndBrik from './Billeder/hcand_spil_ikon.jpg';
import MensJegVenter from './Billeder/mensjegventer.jpg';
import Vægfolie from './Billeder/vægfolie.jpg';
import Rumdeler from './Billeder/rumdeler.jpg';
import godeSamspil from './Billeder/dgodesamspil.jpg';
import blodRobot from './Billeder/robot.jpg';
import blodRobotVideo from './Billeder/Robotvideo_cropped.mp4';
import Stopmotion from './Billeder/stopmotionklip.mp4';
import laptopHG from './Billeder/laptop_hg_forside.jpg';
import ungefrise from './Billeder/udsnit_ungefrise.jpg';
import dustedFolie1 from './Billeder/dusted_folie_1.jpg';
import gavlskærm from './Billeder/gavlskaerm.jpg';
import verdenskort from './Billeder/verdenskort.jpg';
import cloosGolf from './Billeder/cloos_advice_golfbold.jpg';
import racha from './Billeder/rachaayara_logo.jpg';
import rachaBrochure from './Billeder/racha_brochure.jpg';
import laptopRacha from './Billeder/laptop_racha_ayara.jpg';
import KollTrafik from './Billeder/kollektiv_trafik.jpg';
import KollCollage from './Billeder/kollektiv_trafik_collage.jpg';
import gusse from './Billeder/gusse_badge.jpg';
import EnToTand from './Billeder/1-2-tand_logo.jpg';
import BIlogo from './Billeder/beach_inspectors_logo.jpg';
import covidNudging from './Billeder/covid_nudging.jpg';
import covid1 from './Billeder/covid_1.jpg';
import covidVaccination from './Billeder/find_vej_til_covidvaccination.mp4';
import tryllebjerget from './Billeder/tryllebjerget_forside.jpg';
import laptopCloos from './Billeder/laptop_cloos_regnskab.jpg';
import sanderumskolen from './Billeder/sanderumskolen.jpg';
import cellist from './Billeder/cellist.jpg';
import amb from './Billeder/amb.jpg';
import h2væg from './Billeder/vaeg_h2_stor.jpg';
import skab from './Billeder/skab.jpg';
import ambToilet from './Billeder/toilet_ambulatorie.jpg';
import HCAndBrochurer from './Billeder/hcandbrochurer.jpg';
import vindmølle from './Billeder/vindmoelle.jpg';
import afskærmning from './Billeder/afskaermning.jpg';
import kroppenApp from './Billeder/kroppen.jpg';
import indgang from './Billeder/indgang.jpg';
import prøver from './Billeder/proever.jpg';
import vinduesfolie from './Billeder/vinduesfolie.jpg';
import akrylMur from './Billeder/akryl_mur.jpg';


function LøsningerRude() {
    const løsningerSwiperRef = useRef(null);

    let rowsShown = 2;  // update to load a different amount of rows by default
    let maxRows = 10;    // update this when adding new rows to site (which are hidden at the beginning)
    let minRows = 2;    // update if more/less than 2 rows should be "preserved" (not removable by ShowLess button)

    // lyt på venstre / højre piltaster, i tilfælde af at disse vil bruges til at bladre i billederne
    // desuden esc som en ekstra mulighed for at lukke slideshowet
    function lightboxKeyboardControls(e) {
        if (e.key === 'Escape') {
            closeLøsningerLightbox();
        } else if (e.code === 'ArrowLeft') {
            document.getElementsByClassName('løsningerPrevSlide')[0].click();
        } else if (e.code === 'ArrowRight') {
            document.getElementsByClassName('løsningerNextSlide')[0].click();
        }
    }

    // opens the lightbox gallery of løsninger
    function løsningerLightbox(index) {
        // set the slideshow to open on the slide corresponding to the clicked picture (seems to be indexed from 1, not 0)
        løsningerSwiperRef.current.swiper.slideTo(index, 0, false);    // the zero indicates the duration (ms) of the transition animation when sliding to the index

        document.getElementById("løsningerLightboxSlideshow").style.opacity = "1";
        document.getElementById("løsningerLightboxSlideshow").style.zIndex = "119";

        // gem flyout menuen, så den ikke sidder og forstyrrer close knappen
        document.getElementById("flyoutMenuDiv").style.display = "none";

        // enable keyboard controls for the slideshow
        document.addEventListener('keydown', (e) => {
            lightboxKeyboardControls(e);
        });
    }

    function closeLøsningerLightbox() {
        document.getElementById("løsningerLightboxSlideshow").style.opacity = "0";
        document.getElementById("løsningerLightboxSlideshow").style.zIndex = "-10";

        // vis flyout menuen igen
        document.getElementById("flyoutMenuDiv").style.display = "inline-block";

        // disable the keyboard controls for the slideshow
        document.removeEventListener('keydown', (e) => {
            lightboxKeyboardControls(e);
        });
    }

    function showMore() {
        rowsShown++;
        let nextRow = document.getElementById("løsningerRow" + rowsShown);
        nextRow.style.transitionDuration = "1s";  // in case the transitionDuration has been changed by hiding the row, change it back to normal duration of transitions
        nextRow.style.height = "100%";
        nextRow.style.opacity = "1";

        let showMore = document.getElementById("showMoreArrow");
        let showLess = document.getElementById("showLessArrow");

        showMore.style.left = "34%";

        if (rowsShown >= maxRows) {
            showMore.style.display = "none";
            if (window.innerWidth > 850) {
                showLess.style.right = "47.5%";     // the showLess arrow has some margin to keep it centered vertically, so these numbers are different
            } else if (window.innerWidth > 550) {
                showLess.style.right = "45.8%";
            } else {
                showLess.style.right = "43.3%";
            }
        }

        showLess.style.display = "inline-block";
    }

    function showLess() {
        let lastRow = document.getElementById("løsningerRow" + rowsShown);
        // we want the "hide" transition to be quicker
        lastRow.style.transitionDuration = "0.2s";
        lastRow.style.height = "0";
        lastRow.style.opacity = "0";

        rowsShown--;

        let showLess = document.getElementById("showLessArrow");
        let showMore = document.getElementById("showMoreArrow");

        showLess.style.right = "34%";

        // hide the button if there are only the original 2 rows left
        if (rowsShown <= minRows) {
            showLess.style.display = "none";
            if (window.innerWidth > 850) {
                showMore.style.left = "48.5%";
            } else if (window.innerWidth > 550) {
                showMore.style.left = "46.9%";
            } else {
                showMore.style.left = "44.8%";
            }
        }

        // show the "show more" button, in case it was hidden (we are no longer at max rows)
        showMore.style.display = "inline-block";
    }

    // debounce function which prevents a function from being triggered too often (like resize being called every pixel)
    // also the last call will always go through, just with a small delay, so there is no risk of skipping the call onResize that's *just* past the breakpoint
    function debounce(fn, timeout) {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, arguments);
            }, timeout);
        }
    }

    useEffect(
        () => {
            // since we have to be able to click the images to open lightboxes, we cant disable all pointer events for these
            // instead, we protect them from saving by disabling the right click menu on them
            let løsningerBilleder = document.getElementsByClassName('løsningerBilleder');
            for (let i = 0; i < løsningerBilleder.length; i++) {
                løsningerBilleder[i].addEventListener("contextmenu", (e) => { e.preventDefault() });
            }

            const debouncedResize = debounce(() => {
                let showLess = document.getElementById("showLessArrow");

                // when the window is resized, if the showLess arrow is the only one being shown, we have to keep it centered manually
                // because this is not the default value it uses, we cannot keep it centered with the usual CSS responsiveness trick
                // (as that is already in use for keeping it in place when both arrows are being shown)
                if (rowsShown >= maxRows) {
                    if (window.innerWidth > 850) {
                        showLess.style.right = "47.5%";
                    } else if (window.innerWidth > 550) {
                        showLess.style.right = "45.8%";
                    } else {
                        showLess.style.right = "43.3%";
                    }
                }
            }, 100);

            window.addEventListener('resize', debouncedResize);

            // when this component is no longer being rendered, remove the onResize function, so we don't try to call it while this element doesn't exist
            return () => {
                window.removeEventListener('resize', debouncedResize);
            }

        }, []);

    return (
        <>
            {/* Setup af billeder og hashtags i løsninger-ruden på forsiden */}
            <div className='løsningerWrapper'>
                <p className='løsningerHeader overskrift'>LØSNINGER</p>

                <div className='spacingForLøsningerRows'>
                    {/* ROW 1 */}
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={HCAndBrik} alt="HC And ikon - HC And fortæller interaktive historier igennem apps"
                            onClick={() => { løsningerLightbox(1); }} />
                        <p className='løsningerHashtags'>#hcand #spiludvikling #animation #appudvikling #patientinformationforbørn #health #adfærdsdesign</p>
                    </div>
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={MensJegVenter} alt="Aktivitetshæfter, som kan give ro på under ventetid"
                            onClick={() => { løsningerLightbox(2); }} />
                        <p className='løsningerHashtags'>#aktivitetshæfte #tryksager #illustration #branding #visuelidentitet</p>
                    </div>
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={Vægfolie} alt="Vægfolie, der kan hjælpe med at give et helt nyt udtryk"
                            onClick={() => { løsningerLightbox(3); }} />
                        <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                    </div>
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={Rumdeler} alt="En rumdeler, der kan være med til at fornye mulighederne i et rum"
                            onClick={() => { løsningerLightbox(4); }} />
                        <p className='løsningerHashtags'>#rumdeler #interiør #konceptudvikling #visuelidentitet #grafiskdesign #prototype #jern #akryl</p>
                    </div>

                    {/* ROW 2 */}
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={godeSamspil} alt="Det Gode Samspil badge"
                            onClick={() => { løsningerLightbox(5); }} />
                        <p className='løsningerHashtags'>#konceptudvikling #visuelidentitet #logodesign #illustration #tryksager</p>
                    </div>
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={blodRobot} alt="Grafisk design på robot der leverer blodprøver på OUH"
                            onClick={() => { løsningerLightbox(6); }} />
                        <p className='løsningerHashtags'>#visuelidentitet #grafiskdesign #illustration</p>
                    </div>
                    <div className='løsningerColumn'>
                        <video className='løsningerBilleder' autoPlay loop muted playsInline
                            onClick={() => { løsningerLightbox(7); }}
                            src={Stopmotion} type="video/mp4" alt="Stopmotion animation, tegning af en IT-mand">
                            <p className='løsningerHashtags'>Videoen kunne ikke vises.</p>
                        </video>
                        <p className='løsningerHashtags'>#stopmotion #konceptudvikling #animation #illustration</p>
                    </div>
                    <div className='løsningerColumn'>
                        <img className='løsningerBilleder' src={laptopHG} alt="Laptop visende hjemmeside for HG"
                            onClick={() => { løsningerLightbox(8); }} />
                        <p className='løsningerHashtags'>#webdesign</p>
                    </div>

                    {/* ROW 3 (from here on down gets loaded in by clicking the arrow) */}
                    <div id='løsningerRow3' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={ungefrise} alt="Frise hen ad væg"
                                onClick={() => { løsningerLightbox(9); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #grafiskdesign #wallgraphic #akryl</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={dustedFolie1} alt="Foliedesign på vindue"
                                onClick={() => { løsningerLightbox(10); }} />
                            <p className='løsningerHashtags'>#vinduesfolie #dustedfolie #diskretionsfolie #grafiskdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={gavlskærm} alt="Afskærmning til opdeling af rum"
                                onClick={() => { løsningerLightbox(11); }} />
                            <p className='løsningerHashtags'>#afskærmning #foldeskærm #customdesign #daylightpanels #silentiaskærmsystem #konceptudvikling</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={verdenskort} alt="Verdenskort på væg"
                                onClick={() => { løsningerLightbox(12); }} />
                            <p className='løsningerHashtags'>#grafiskdesign #customdesign #verdenskort #digitalprint #pvc</p>
                        </div>
                    </div>

                    {/* ROW 4 */}
                    <div id='løsningerRow4' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={cloosGolf} alt="Cloos Advice golfbold"
                                onClick={() => { løsningerLightbox(13); }} />
                            <p className='løsningerHashtags'>#logodesign #markedsføring #merchandise</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={racha} alt="Racha Ayara logo"
                                onClick={() => { løsningerLightbox(14); }} />
                            <p className='løsningerHashtags'>#logodesign #konceptudvikling #visuelidentitet #brevlinje #grafiskdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={rachaBrochure} alt="Racha Ayara folder"
                                onClick={() => { løsningerLightbox(15); }} />
                            <p className='løsningerHashtags'>#konceptudvikling #visuelidentitet #brochure #printdesign #tryksager</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={laptopRacha} alt="Laptop med hjemmeside til Racha Ayara"
                                onClick={() => { løsningerLightbox(16); }} />
                            <p className='løsningerHashtags'>#webdesign</p>
                        </div>
                    </div>

                    {/* ROW 5 */}
                    <div id='løsningerRow5' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={KollTrafik} alt="Kollektiv Trafik logo"
                                onClick={() => { løsningerLightbox(17); }} />
                            <p className='løsningerHashtags'>#illustration #digitaldesign #kampagne</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={KollCollage} alt="Collage til Kollektiv Trafik"
                                onClick={() => { løsningerLightbox(18); }} />
                            <p className='løsningerHashtags'>#illustration #digitaldesign #kampagne</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={gusse} alt="Gusse badge"
                                onClick={() => { løsningerLightbox(19); }} />
                            <p className='løsningerHashtags'>#logodesign #appudvikling #sprogversionering #gusse #health</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={EnToTand} alt="1-2-Tand logo"
                                onClick={() => { løsningerLightbox(20); }} />
                            <p className='løsningerHashtags'>#konceptudvikling #logodesign #appudvikling #1-2-tand #health</p>
                        </div>
                    </div>

                    {/* ROW 6 */}
                    <div id='løsningerRow6' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={BIlogo} alt="Beach Inspectors logo"
                                onClick={() => { løsningerLightbox(21); }} />
                            <p className='løsningerHashtags'>#logodesign #illustration #digitaldesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={covidNudging} alt="Design af nudging i forbindelse med covid"
                                onClick={() => { løsningerLightbox(22); }} />
                            <p className='løsningerHashtags'>#gulvfolie #covid19 #floorgraphic #grafiskdesign #adfærdsdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={covid1} alt="Vægdesign"
                                onClick={() => { løsningerLightbox(23); }} />
                            <p className='løsningerHashtags'>#grafiskdesign #digitalprint #wallgraphic #covid19 #adfærdsdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <video className='løsningerBilleder' autoPlay loop muted playsInline
                                onClick={() => { løsningerLightbox(24); }}
                                src={covidVaccination} type="video/mp4" alt="Andefødder viser vej til covid-vaccination">
                                <p className='løsningerHashtags'>Videoen kunne ikke vises.</p>
                            </video>
                            <p className='løsningerHashtags'>#floorgraphic #wayfinding #covid19 #adfærdsdesign</p>
                        </div>
                    </div>

                    {/* ROW 7 */}
                    <div id='løsningerRow7' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={tryllebjerget} alt="Tryllebjerget logo"
                                onClick={() => { løsningerLightbox(25); }} />
                            <p className='løsningerHashtags'>#konceptudvikling #illustration #digitaldesign #tryllebjerget</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={laptopCloos} alt="Laptop med Cloos Regnskab hjemmeside"
                                onClick={() => { løsningerLightbox(26); }} />
                            <p className='løsningerHashtags'>#webdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={sanderumskolen} alt="Vægdesign på Sanderumskolen"
                                onClick={() => { løsningerLightbox(27); }} />
                            <p className='løsningerHashtags'>#restaurering #sanderumskolen</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={cellist} alt="Animeret cellist"
                                onClick={() => { løsningerLightbox(28); }} />
                            <p className='løsningerHashtags'>#illustration #digitaldesign #konceptudvikling</p>
                        </div>
                    </div>

                    {/* ROW 8 */}
                    <div id='løsningerRow8' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={amb} alt="Venteværelse med spændende folie på væggen"
                                onClick={() => { løsningerLightbox(29); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={h2væg} alt="Vægfolie der giver ny energi i rummet"
                                onClick={() => { løsningerLightbox(30); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={skab} alt="Skabe med natur på"
                                onClick={() => { løsningerLightbox(31); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={ambToilet} alt="Ambulatorie-toilet med interessant vægfolie"
                                onClick={() => { løsningerLightbox(32); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                        </div>
                    </div>

                    {/* ROW 9 */}
                    <div id='løsningerRow9' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={HCAndBrochurer} alt="Foldere med HC And"
                                onClick={() => { løsningerLightbox(33); }} />
                            <p className='løsningerHashtags'>#printdesign #tryksager #markedsføring #appudvikling #health</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={vindmølle} alt="Illustration af vindmøllebygning"
                                onClick={() => { løsningerLightbox(34); }} />
                            <p className='løsningerHashtags'>#illustration #digital</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={afskærmning} alt="Afskærmning i et rum"
                                onClick={() => { løsningerLightbox(35); }} />
                            <p className='løsningerHashtags'>#rumdeler #interiør #lyddæmpning #konceptudvikling #visuelidentitet #grafiskdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={kroppenApp} alt="Ikon til app om kroppen"
                                onClick={() => { løsningerLightbox(36); }} />
                            <p className='løsningerHashtags'>#kroppen #spiludvikling #animation #appudvikling #patientinformationforbørn #health</p>
                        </div>
                    </div>

                    {/* ROW 10 */}
                    <div id='løsningerRow10' className='loadingRow'>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={indgang} alt="Vægdesign svane"
                                onClick={() => { løsningerLightbox(37); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={prøver} alt="Forskellige prøver af folie"
                                onClick={() => { løsningerLightbox(38); }} />
                            <p className='løsningerHashtags'>#vægfolie #digitalprint #visuelidentitet #grafiskdesign #wallgraphic</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={vinduesfolie} alt="Foliedesign på vindue"
                                onClick={() => { løsningerLightbox(39); }} />
                            <p className='løsningerHashtags'>#vinduesfolie #dustedfolie #diskretionsfolie #grafiskdesign</p>
                        </div>
                        <div className='løsningerColumn'>
                            <img className='løsningerBilleder' src={akrylMur} alt="Akryldesign på mur"
                                onClick={() => { løsningerLightbox(40); }} />
                            <p className='løsningerHashtags'>#grafiskdesign #digitalprint #akryl</p>
                        </div>
                    </div>

                    {/* If adding more rows, the things that need to be updated are the index of the løsningerLightbox, as well as the id of the wrapping div
                        which should be counted up. The pictures will also need to be added to the Swiper below. 
                        Also the variables in the top should be updated to match the new number of rows, otherwise they won't all be able to unfold. */}

                </div>

                <div id='showMoreArrow' title='Se flere løsninger' onClick={showMore}></div>
                <div id='showLessArrow' title='Se færre løsninger' onClick={showLess}></div>
            </div>

            {/* Lightbox slideshow for all the pictures, opened by clicking one of them */}
            <div className='løsningerSlideshowBackground' id='løsningerLightboxSlideshow' onClick={() => { closeLøsningerLightbox(); }}>
                <div className='løsningerCloseSlideshowBtn' onClick={() => { closeLøsningerLightbox(); }}>
                    <div className='løsningerSlideCloseBar1'></div>
                    <div className='løsningerSlideCloseBar2'></div>
                    <div className='løsningerSlideCloseBar3'></div>
                </div>

                <div className='løsningerSlideshowContainer' onClick={(e) => { e.stopPropagation(); }}>
                    <div className="løsningerPrevSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='løsningerPrevBar1'></div>
                        <div className='løsningerPrevBar2'></div>
                    </div>
                    <div className="løsningerNextSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='løsningerNextBar1'></div>
                        <div className='løsningerNextBar2'></div>
                    </div>

                    <Swiper
                        ref={løsningerSwiperRef}
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        speed={500}
                        loop={true}
                        navigation={{ prevEl: '.løsningerPrevSlide', nextEl: '.løsningerNextSlide' }}
                    /*breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 10 }
                    }}*/
                    >
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={HCAndBrik} alt="HC And ikon" onClick={(e) => { e.stopPropagation(); /* to not trigger the background onclick */ }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={MensJegVenter} alt="Aktivitetshæfter" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={Vægfolie} alt="Vægfolie" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={Rumdeler} alt="Rumdeler" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={godeSamspil} alt="Det Gode Samspil badge" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <video className='løsningerLightboxSlides' autoPlay loop muted playsInline
                                    src={blodRobotVideo} onClick={(e) => { e.stopPropagation(); }}>
                                    <p className='løsningerLightboxText'>Videoen kunne ikke vises.</p>
                                </video>
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <video className='løsningerLightboxSlides' autoPlay loop muted playsInline
                                    src={Stopmotion} onClick={(e) => { e.stopPropagation(); }}>
                                    <p className='løsningerLightboxText'>Videoen kunne ikke vises.</p>
                                </video>
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={laptopHG} alt="Laptop visende hjemmeside for HG" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={ungefrise} alt="Frise hen ad væg" onClick={(e) => { e.stopPropagation(); }} />

                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={dustedFolie1} alt="Foliedesign på vindue" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={gavlskærm} alt="Afskærmning til opdeling af rum" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={verdenskort} alt="Verdenskort på væg" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={cloosGolf} alt="Cloos Advice golfbold" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={racha} alt="Racha Ayara logo" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={rachaBrochure} alt="Racha Ayara folder" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={laptopRacha} alt="Laptop med hjemmeside til Racha Ayara" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={KollTrafik} alt="Kollektiv Trafik logo" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={KollCollage} alt="Collage til Kollektiv Trafik" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={gusse} alt="Gusse badge" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={EnToTand} alt="1-2-Tand logo" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={BIlogo} alt="Beach Inspectors logo" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={covidNudging} alt="Design af nudging i forbindelse med covid" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={covid1} alt="Vægdesign" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <video className='løsningerLightboxSlides' autoPlay loop muted playsInline
                                    src={covidVaccination} onClick={(e) => { e.stopPropagation(); }}>
                                    <p className='løsningerLightboxText'>Videoen kunne ikke vises.</p>
                                </video>
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={tryllebjerget} alt="Tryllebjerget logo" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={laptopCloos} alt="Laptop med Cloos Regnskab hjemmeside" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={sanderumskolen} alt="Vægdesign på Sanderumskolen" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={cellist} alt="Animeret cellist" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={amb} alt="Venteværelse med spændende folie på væggen" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={h2væg} alt="Vægfolie der giver ny energi i rummet" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={skab} alt="Skabe med natur på" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={ambToilet} alt="Ambulatorie-toilet med interessant vægfolie" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={HCAndBrochurer} alt="Foldere med HC And" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={vindmølle} alt="Illustration af vindmøllebygning" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={afskærmning} alt="Afskærmning i et rum" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={kroppenApp} alt="Ikon til app om kroppen" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={indgang} alt="Vægdesign svane" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={prøver} alt="Forskellige prøver af folie" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={vinduesfolie} alt="Foliedesign på vindue" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='løsningerLightboxSwiperSlideAlignment'>
                                <img className='løsningerLightboxSlides' src={akrylMur} alt="Akryldesign på mur" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default LøsningerRude;