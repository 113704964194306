import { useEffect } from 'react';
import './Nature.css';
import Header from './nature_underside.jpg';
import BiologerVideo from './Video/biologer_1080p_60FPS.webm';
import BiologerVideoCompressed from './Video/biologer_compressed.mp4';
import Thumbnail from './Video/thumbnail.png';


function Nature() {
    // debounce function which prevents a function from being triggered too often (like resize being called every pixel)
    // also the last call will always go through, just with a small delay, so there is no risk of skipping the call onResize that's *just* past the breakpoint
    function debounce(func, timeout) {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, arguments);
            }, timeout);
        }
    }

    // styrer hvor orddelingen sker i "naturgenopretning"
    function controlWordWrap() {
        // hvis vinduets vidde er på en størrelse, hvor "Naturgenopretning" ikke bliver delt, viser vi hele ordet samlet
        if (window.innerWidth >= 1680 || (window.innerWidth <= 1200 && window.innerWidth > 341)) {
            document.getElementById("natureTitle").innerHTML = "Case <span style='color:#ee8939'>:</span> Naturgenopretning";
        } else {      // ellers indsætter vi en bindestreg, så vi kan styre hvor orddelingen sker
            document.getElementById("natureTitle").innerHTML = "Case <span style='color:#ee8939'>:</span> Natur-genopretning";
        }
    }

    useEffect(
        () => {
            // to prevent saving of the video, we disable the right click menu on it
            let video = document.getElementsByClassName('natureVideo')[0];
            video.addEventListener("contextmenu", (e) => { e.preventDefault() });

            // when the component is loaded, we need to make sure the correct word wrapping is used
            controlWordWrap();

            const debouncedResize = debounce(() => {
                controlWordWrap();
            }, 25);

            // and every time the window is resized, we want to make sure we keep using the correct word wrapping
            window.addEventListener('resize', debouncedResize);

            // when this component is no longer being rendered, remove the onResize function, so we don't try to call it while this element doesn't exist
            return () => {
                window.removeEventListener('resize', debouncedResize);
            }

        }, []);

    return (
        <>
            <img src={Header} alt="Forsidebillede af naturgenopretningsprojekt i 3D animation" className='undersideHeaderImage'></img>

            <div className='undersideColumnContainer'>

                <div className='undersideColumn'>
                    <p className='undersideTagline'>NATURE</p>
                    <p className='undersideTitle' id='natureTitle'>Case <span style={{ color: "#ee8939" }}>:</span> Naturgenopretning</p>
                    <p className='undersideText'>At forestille sig naturgenopretning mens det stadig er på tegnebrættet er en vanskelig
                        opgave for de fleste. Hvordan viser man biodiversitet ’efter’, når man kun kan se ’før’ med det blotte øje?<br /><br />
                        Det problem løser vi med naturtro 3D‐gengivelser, der visualiserer, hvordan fremtiden kan komme til at se ud.<br /><br />
                        Vi har fx arbejdet med at gøre det traditionelle afvandingskort tydeligt for enhver.
                        Ved hjælp af 3D animationer gøres forslag til naturgenopretning naturtro. Det afhjælper usikkerhed om beslutninger
                        i forhandlings‐ og anlægsfasen, og gør det muligt at forstå perspektivet ‐ også for de beslutningstagere,
                        der ikke kan læse de traditionelle afvandingskort.</p>
                </div>

                <div className='undersideColumn'>
                    <p className='undersideSubHeaders natureFixTop'>Vådområder og andre projekter fra NATURE...</p>
                    <ul className='natureList'>
                        <li>Udarbejdes med udgangspunkt i uvildig supervision fra biologer hos Bangsgaard & Paludan</li>
                        <li>Film skræddersys, fx med luftfoto, dronefilm, flere kameravinkler, grafer og tekst</li>
                        <li>Der leveres før‐/efterbilleder til præsentationer og publikationer</li>
                        <li>Leveres i filformater, der kan afvikles på alle gængse platforme</li>
                        <li>Mulighed for at få præcis de ønskede detaljer i fokus</li>
                    </ul>
                </div>

                <div className='undersideColumn'>
                    <p className='undersideSubHeaders nopadding natureFixTop'>NATURE - video</p>
                    <video className='natureVideo' width="100%" preload="true" controls
                        poster={Thumbnail}>
                        <source src={BiologerVideo} />
                        <source src={BiologerVideoCompressed} />
                        Videoen kunne ikke loades.
                    </video>
                </div>

                <p className='kontaktTekst'>Overvejer I at visualisere jeres natur-projekter i 3D, så ring og book et møde på 8813 1030 eller send en mail til kontakt@1030.dk</p>

            </div>


        </>
    )
}

export default Nature;