import './Coprod.css';
import Header from './co_production_b.jpg';
import CoproductionImg from './stopmotion_small.jpg';



function Coprod() {
    return (
        <>
            <img src={Header} alt="Forsidebillede til co-production - vi hjælper dig med at komme fra idé til fuldendt projekt" className='undersideHeaderImage'></img>

            <div className='undersideColumnContainer'>

                <div className='undersideColumn'>
                    <p className='undersideTagline'>CO-PRODUCTION</p>
                    <p className='undersideTitle' id='coprodTitle'>MULTIMEDIADESIGN</p>
                    <p className='undersideText'>Vejen fra idé til struktur, produktion og lancering kan være snørklet, hvis man ikke har
                        erfaring med at arbejde med kravspecifikationer, informationsdesign, grafik og programmering. Derfor går vi gerne ind i
                        udviklingssamarbejder med personer og virksomheder, der har en god idé i skuffen eller et budskab, der ikke kan kommunikeres
                        med ord alene. Vi hjælper med at sætte billeder og lyd på ideer og budskaber.</p>
                </div>

                <div className='undersideColumn'>
                    <ul className='coprodList'>
                        <li>Animation/tegnefilm/stop‐motion</li>
                        <li>Apps</li>
                        <li>Augmented Reality (AR) og Virtual Reality (VR)</li>
                        <li>Spiludvikling</li>
                        <li>Udvikles til at kunne afvikles på alle relevante platforme</li>
                    </ul>
                </div>

                <div className='undersideColumn coprodImgJustify'>
                    <img className='coprodImg noPointerEvents' alt="Vi kan hjælpe med at tegne og animere" src={CoproductionImg} />
                </div>

                <p className='kontaktTekst'>Har I en idé i skuffen eller et budskab, der fortjener at få en god visuel form, så ring og book et møde på 8813 1030 eller send en mail til kontakt@1030.dk</p>

            </div>


        </>
    )
}

export default Coprod;