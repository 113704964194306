import './Adfærd.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Header from './adfaerdsdesign_underside.jpg';
import Adfærd1 from './Billeder/adfaerd_1.jpg';
import Adfærd2 from './Billeder/adfaerd_2.jpg';
import Adfærd3 from './Billeder/adfaerd_3.jpg';


function Adfærd() {
    return (
        <>
            <img src={Header} alt="Forsidebillede af adfærdsdesign, der viser hvordan designdetaljer kan ændre udtrykket og puste liv i et rum" className='undersideHeaderImage'></img>

            <div className='undersideColumnContainer'>

                <div className='undersideColumn'>
                    <p className='undersideTagline'>ADFÆRDSDESIGN</p>
                    <p className='undersideTitle' id='adfærdTitle'>"VENLIGE PUF"</p>
                    <p className='undersideText'>Gammeldags envejskommunikation på brochurer og foldere virker på dem, der i forvejen er interesseret
                        i budskabet. Men hvad med dem, der ikke er motiverede? Til dem er det en god idé at arbejde med nudging. Nudging er også kendt
                        som ’venlige puf’; som en måde at lede borgere, brugere og patienter i den ønskede retning. <br /><br />
                        Vi hjælper børnehaver, hospitaler, plejehjem, klinikker og andre institutioner med at designe
                        situationsbestemte brugeroplevelser, der virker motiverende, inspirerende, beroligende eller lærende – alt efter opgavens karakter.</p>
                </div>

                <div className='undersideColumn'>
                    <ul className='adfaerdList'>
                        <li>Motiverende og adfærdsguidende elementer og motiver</li>
                        <li>Kreative løsninger på wayfinding; på det, at kunne finde rundt og finde frem til det rigtige kontor, afdeling eller den rigtige handling</li>
                        <li>Udviklet på baggrund af grundig foranalyse sammen med kunden</li>
                        <li>Forbedrer arbejdsmiljø med fx akustikdæmpende elementer, visuelle blikfang eller udsmykning</li>
                        <li>Vender tilspidsede situationer til fx ro eller leg</li>
                        <li>Udformes i rengøringsvenlige materialer</li>
                    </ul>
                </div>

                <div className='undersideColumn'>
                    <div className='adfærdCarouselWrapper'>
                        {/* NAVIGATION BUTTONS */}
                        <div className="adfærdCarouselPrev" onClick={(e) => { e.stopPropagation(); }}>
                            <div className='adfærdPrevBar1'></div>
                            <div className='adfærdPrevBar2'></div>
                        </div>
                        <div className="adfærdCarouselNext" onClick={(e) => { e.stopPropagation(); }}>
                            <div className='adfærdNextBar1'></div>
                            <div className='adfærdNextBar2'></div>
                        </div>

                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={10}
                            slidesPerView={1}
                            speed={500}
                            loop={true}
                            navigation={{ prevEl: '.adfærdCarouselPrev', nextEl: '.adfærdCarouselNext' }}
                            breakpoints={{
                                640: { slidesPerView: 2, spaceBetween: 10 }
                            }}
                        >
                            <SwiperSlide><img className='adfærdSliderImg noPointerEvents' src={Adfærd1} alt="Illustration af adfærdsdesign - Andefødder hjælper med at vise vej" /></SwiperSlide>
                            <SwiperSlide><img className='adfærdSliderImg noPointerEvents' src={Adfærd2} alt="Illustration af adfærdsdesign - Rumdeler giver flere muligheder" /></SwiperSlide>
                            <SwiperSlide><img className='adfærdSliderImg noPointerEvents' src={Adfærd3} alt="Illustration af adfærdsdesign - Udseendet af omgivelserne har stor betydning" /></SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className='undersideColumnContainer'>

                <div className='undersideColumn willHide'>
                    <img className='adfærdImg1 noPointerEvents' alt="Adfærdsdesignbillede - Andefødder hjælper med at vise vej" src={Adfærd1} />
                </div>

                <div className='undersideColumn willHide'>
                    <img className='adfærdImg2 noPointerEvents' alt="Adfærdsdesignbillede - Rumdeler giver flere muligheder" src={Adfærd2} />
                </div>

                <div className='undersideColumn willHide'>
                    <img className='adfærdImg3 noPointerEvents' alt="Adfærdsdesignbillede - Udseendet af omgivelserne har stor betydning" src={Adfærd3} />
                </div>

                <p className='kontaktTekst'>Overvejer I at arbejde med adfærdsdesign, så send en mail til kontakt@1030.dk eller ring til Susanne på 8813 1030</p>

            </div>


        </>
    )
}

export default Adfærd;