import './Day2day.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

// logo imports
import Header from './day2day.jpg';
import Agrosa from './Billeder/Logoer/agrosa.jpg';
import BInspectors from './Billeder/Logoer/beach_inspectors.jpg';
import BornSundhed from './Billeder/Logoer/boern_sundhed.jpg';
import CloosA from './Billeder/Logoer/cloos_advice.jpg';
import CloosR from './Billeder/Logoer/cloos_regnskab.jpg';
import eArtifact from './Billeder/Logoer/e_artifactory.jpg';
import FynPsyk from './Billeder/Logoer/fyns_psykolog_praksis.jpg';
import GGI from './Billeder/Logoer/ggi.jpg';
import Ginkushie from './Billeder/Logoer/ginkushe.jpg';
import MathMag from './Billeder/Logoer/mathmagicians.jpg';
import MISikkerhed from './Billeder/Logoer/mere_it_sikkerhed.gif';
import OMC from './Billeder/Logoer/omc.jpg';
import Pilehave from './Billeder/Logoer/pilehave.jpg';
import Racha from './Billeder/Logoer/rachaayara.jpg';
import RenzA from './Billeder/Logoer/renz_a.jpg';
import Sundhedsplejerske from './Billeder/Logoer/sundhedsplejerske.jpg';
import Tenna from './Billeder/Logoer/tenna.jpg';
import TF from './Billeder/Logoer/tf.jpg';

// web imports
import RachaWeb from './Billeder/Web/laptop_racha_ayara.jpg';
import CloosRegnskabWeb from './Billeder/Web/laptop_cloos_regnskab.jpg';
import CloosAdviceWeb from './Billeder/Web/laptop_cloos_advice.jpg';
import HGWeb from './Billeder/Web/laptop_hg.jpg';
import HGForsideWeb from './Billeder/Web/laptop_hg_forside.jpg';


function Day2day() {
    // IKKE I BRUG PT, DA TITEL-KNAPPERNE ER KOMMENTERET UD
    // opens the slideshow corresponding to the argument (Logo / Illustrationer / Web / Printed)
    function openLightboxGallery(type) {
        document.getElementById("d2dSlideshow" + type).style.opacity = "1";
        document.getElementById("d2dSlideshow" + type).style.zIndex = "119";

        // gem flyout menuen, så den ikke sidder og forstyrrer close knappen
        document.getElementById("flyoutMenuDiv").style.display = "none";
    }

    function closeLightboxGallery(type) {
        document.getElementById("d2dSlideshow" + type).style.opacity = "0";
        document.getElementById("d2dSlideshow" + type).style.zIndex = "-10";

        // vis flyout menuen igen
        document.getElementById("flyoutMenuDiv").style.display = "inline-block";
    }

    return (
        <>
            <img src={Header} alt="Forsidebillede til day2day - stedet i 10:30, hvor vi hjælper dig med dine grafiske opgaver i dagligdagen" className='undersideHeaderImage'></img>

            <div className='undersideColumnContainer'>

                <div className='undersideColumn'>
                    <p className='undersideTagline'>DAY2DAY</p>
                    <p className='undersideTitle' id='d2dTitle'>GRAFISKE OPGAVER</p>
                    <p className='undersideText'>Day2Day er det sted i 10:30, hvor vi hjælper virksomheder med at løse mere traditionelle grafiske opgaver.<br /><br />
                        Vi er virksomhedernes forlængede kommunikationsafdeling, man kan tilkalde og med få timers varsel og få lavet gedigent grafisk håndværk
                        med stor faglig indsigt i en bred vifte af brancher og problemstillinger. Både til de hurtige dag‐til‐dag produkter og til de langvarige
                        strategiske relationer med virksomhederne om deres grafiske kommunikation.</p>
                </div>

                <div className='undersideColumn'>
                    <p className='undersideSubHeaders' id='d2dListHeader'>Vi arbejder både med online og offline kommunikation, fx …</p>
                    <ul className='day2dayList'>
                        <li>Visuel identitet, fx logo, brevskabelon og visitkort</li>
                        <li>Illustrationer, ikoner, infografik og animationsvignetter</li>
                        <li>Brochurer, foldere og præsentationer</li>
                        <li>Plakater, bannere, roll‐up – og meget mere</li>
                        <li>Skabelonbaserede hjemmesider til afvikling på computer, tablets og smartphones</li>
                    </ul>
                </div>

                <div className='undersideColumn'>
                </div>
            </div>

            <div className='undersideColumnContainer'>

                {/* DISSE ER "TITEL-KNAPPERNE", SOM KAN ÅBNE SLIDESHOWS - ALLE FIRE ER SAT OP TIL AT ÅBNE ET SWIPER SLIDESHOW,
                    DOG HAR KUN LOGO OG WEB NOGET CONTENT I DEM. DESUDEN MANGLER SLIDESHOWS NOGET TILRETNING FOR AT SE GODT UD (ISÆR PÅ MOBIL).
                    HER KAN EVT HENTES INSPIRATION FRA LØSNINGER-RUDENS SLIDESHOW, MEN DER ØNSKES UMIDDELBART ET LIDT ANDET DESIGN HER
                    - EX. HVID KANT OMKRING LOGOERNE. DESUDEN ER BILLEDERNE VIST NOK IKKE NØDVENDIGVIS KVADRATISKE HER. */}

                {/*<div className='d2dColumn'><p className='undersideTitle d2dFeatureTitle' style={{ textAlign: "center" }} onClick={() => { openLightboxGallery("Logo"); }}>Logo</p></div>*/}

                {/*<div className='d2dColumn'><p className='undersideTitle d2dFeatureTitle' style={{ textAlign: "center" }} onClick={() => { openLightboxGallery("Illustrationer"); }}>Illustrationer</p></div>*/}

                {/*<div className='d2dColumn'><p className='undersideTitle d2dFeatureTitle' style={{ textAlign: "center" }} onClick={() => { openLightboxGallery("Web"); }}>Web</p></div>*/}

                {/*<div className='d2dColumn'><p className='undersideTitle d2dFeatureTitle' style={{ textAlign: "center" }} onClick={() => { openLightboxGallery("Printed"); }}>Printed</p></div>*/}

                <p className='kontaktTekst'>Har jeres B2C- eller B2B-kommunikation brug for et grafisk løft, så send en mail til kontakt@1030.dk eller ring til Susanne på 8813 1030</p>
            </div>

            {/* SLIDESHOWS HERUNDER, SKJULT SOM UDGANGSPUNKT - ÅBNES VED TRYK PÅ "TITEL-KNAP" */}
            {/* LOGO Slideshow */}
            <div className='d2dGallerySlideshowBackground' id='d2dSlideshowLogo' onClick={() => { closeLightboxGallery("Logo"); }}>
                <div className='d2dCloseSlideshowBtn' onClick={() => { closeLightboxGallery("Logo"); }}>
                    <div className='d2dSlideCloseBar1'></div>
                    <div className='d2dSlideCloseBar2'></div>
                    <div className='d2dSlideCloseBar3'></div>
                </div>

                <div className='d2dSlideshowContainer' onClick={(e) => { e.stopPropagation(); }}>
                    <div className="d2dPrevSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dPrevBar1'></div>
                        <div className='d2dPrevBar2'></div>
                    </div>
                    <div className="d2dNextSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dNextBar1'></div>
                        <div className='d2dNextBar2'></div>
                    </div>

                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        speed={500}
                        loop={true}
                        navigation={{ prevEl: '.d2dPrevSlide', nextEl: '.d2dNextSlide' }}
                    /*breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 10 }
                    }}*/
                    >
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={Agrosa} alt="Logo - Agrosa Consult" onClick={(e) => { e.stopPropagation(); /* to not trigger the background onclick */ }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={BInspectors} alt="Logo - Beach Inspectors" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={BornSundhed} alt="Logo - Børn og Sundhed" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={CloosA} alt="Logo - Cloos Advice" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={CloosR} alt="Logo - Cloos Regnskab" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={eArtifact} alt="Logo - E-Artifactory" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={FynPsyk} alt="Logo - Fyns Psykolog Praksis" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={GGI} alt="Logo - Go Green Invest ApS" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={Ginkushie} alt="Logo - Ginkushe" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={MathMag} alt="Logo - MathMagicians" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={MISikkerhed} alt="Logo - Mere IT-sikkerhed" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={OMC} alt="Logo - OMC" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={Pilehave} alt="Logo - Pilehavekartofler" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={Racha} alt="Logo - Racha Ayara Private Residence" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={RenzA} alt="Logo - Renz-a" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={Sundhedsplejerske} alt="Logo - Sundhedsplejerske.com" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={Tenna} alt="Logo - Tenna Bjerre" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesLogo' src={TF} alt="Logo - TF Music Production" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* ILLUSTRATIONER Slideshow */}
            <div className='d2dGallerySlideshowBackground' id='d2dSlideshowIllustrationer' onClick={() => { closeLightboxGallery("Illustrationer"); }}>
                <div className='d2dCloseSlideshowBtn' onClick={() => { closeLightboxGallery("Illustrationer"); }}>
                    <div className='d2dSlideCloseBar1'></div>
                    <div className='d2dSlideCloseBar2'></div>
                    <div className='d2dSlideCloseBar3'></div>
                </div>

                <div className='d2dSlideshowContainer' onClick={(e) => { e.stopPropagation(); }}>
                    <div className="d2dPrevSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dPrevBar1'></div>
                        <div className='d2dPrevBar2'></div>
                    </div>
                    <div className="d2dNextSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dNextBar1'></div>
                        <div className='d2dNextBar2'></div>
                    </div>

                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        speed={500}
                        loop={true}
                        navigation={{ prevEl: '.d2dPrevSlide', nextEl: '.d2dNextSlide' }}
                    /*breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 10 }
                    }}*/
                    >
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesIllustrationer' src={Agrosa} alt="Logo - Agrosa Consult" onClick={(e) => { e.stopPropagation(); /* to not trigger the background onclick */ }} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* WEB Slideshow */}
            <div className='d2dGallerySlideshowBackground' id='d2dSlideshowWeb' onClick={() => { closeLightboxGallery("Web"); }}>
                <div className='d2dCloseSlideshowBtn' onClick={() => { closeLightboxGallery("Web"); }}>
                    <div className='d2dSlideCloseBar1'></div>
                    <div className='d2dSlideCloseBar2'></div>
                    <div className='d2dSlideCloseBar3'></div>
                </div>

                <div className='d2dSlideshowContainer' onClick={(e) => { e.stopPropagation(); }}>
                    <div className="d2dPrevSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dPrevBar1'></div>
                        <div className='d2dPrevBar2'></div>
                    </div>
                    <div className="d2dNextSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dNextBar1'></div>
                        <div className='d2dNextBar2'></div>
                    </div>

                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        speed={500}
                        loop={true}
                        navigation={{ prevEl: '.d2dPrevSlide', nextEl: '.d2dNextSlide' }}
                    /*breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 10 }
                    }}*/
                    >
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesWeb' src={RachaWeb} alt="Laptop visende website til Racha Ayara" onClick={(e) => { e.stopPropagation(); /* to not trigger the background onclick */ }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesWeb' src={CloosRegnskabWeb} alt="Laptop visende website til Cloos Regnskab" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesWeb' src={CloosAdviceWeb} alt="Laptop visende website til Cloos Advice" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesWeb' src={HGForsideWeb} alt="Laptop visende forside på HG hjemmeside" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesWeb' src={HGWeb} alt="Laptop visende HG hjemmeside" onClick={(e) => { e.stopPropagation(); }} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* PRINTED Slideshow */}
            <div className='d2dGallerySlideshowBackground' id='d2dSlideshowPrinted' onClick={() => { closeLightboxGallery("Printed"); }}>
                <div className='d2dCloseSlideshowBtn' onClick={() => { closeLightboxGallery("Printed"); }}>
                    <div className='d2dSlideCloseBar1'></div>
                    <div className='d2dSlideCloseBar2'></div>
                    <div className='d2dSlideCloseBar3'></div>
                </div>

                <div className='d2dSlideshowContainer' onClick={(e) => { e.stopPropagation(); }}>
                    <div className="d2dPrevSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dPrevBar1'></div>
                        <div className='d2dPrevBar2'></div>
                    </div>
                    <div className="d2dNextSlide" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='d2dNextBar1'></div>
                        <div className='d2dNextBar2'></div>
                    </div>

                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        speed={500}
                        loop={true}
                        navigation={{ prevEl: '.d2dPrevSlide', nextEl: '.d2dNextSlide' }}
                    /*breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 10 }
                    }}*/
                    >
                        <SwiperSlide className='d2dSwiperSlideAlignment'>
                            <img className='d2dSlidesPrinted' src={Agrosa} alt="Logo - Agrosa Consult" onClick={(e) => { e.stopPropagation(); /* to not trigger the background onclick */ }} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default Day2day;