import './ChangeCookies.css';
import CookieIcon from './cookie_icon.png';
import { useCookies } from 'react-cookie';


function ChangeCookies() {
    const [cookies, setCookie, removeCookie] = useCookies(['cookieConsent']);

    function resetCookiePreferences() {
        removeCookie("cookieConsent", { path: '/' });
    }

    return (
        <div id='changeCookieSettings' onClick={resetCookiePreferences} >
            <img id='changeCookieIcon' src={CookieIcon} alt="Ikon til at skifte cookie-præferencer" title='Klik her for at se cookie-banneret igen.' />
        </div>
    )
}

export default ChangeCookies;