import './Health.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Header from './health_underside.jpg';
import Health1 from './Billeder/hcandinteraktiv.png';
import Health2 from './Billeder/touchskærm.png';
import Health3 from './Billeder/mensjegventer.png';


function Health() {
    return (
        <>
            <img src={Header} alt="Forsidebillede af børn, der ved hjælp af HC And kan lære og blive forberedt på diverse sundhedsprocedurer på en sjov og interaktiv måde"
                className='undersideHeaderImage'></img>

            <div className='undersideColumnContainer'>

                <div className='undersideColumn'>
                    <p className='undersideTagline'>HEALTH</p>
                    <p className='undersideTitle' id='healthTitle'>Case <span style={{ color: "#ee8939" }}>:</span> HC And</p>
                    <p className='undersideText'>God kommunikation kan være en afgørende faktor i det vellykkede møde med hospitalet, både når det
                        kommer til patientens mestringsstrategier, til personalets planlægning og til hospitalets økonomi. Alt dette er HC And et eksempel på.<br /><br />
                        HC And er en lang række kommunikationsprodukter på forskellige medieplatforme rettet mod de 3 – 7årige børn, der kommer i forbindelse
                        med danske og enkelte svenske og norske hospitaler. <br /><br />
                        Siden 2005 har 10:30 sammen med børnefamilier og medarbejdere ved H.C. Andersen Børne‐ og Ungehospital på
                        Odense Universitetshospital konceptudviklet og implementeret HC And‐universet, med det formål at oplyse og skabe leg,
                        læring og tryghed rundt om indlæggelsesforløbet.</p>
                </div>

                <div className='undersideColumn'>
                    <p className='undersideSubHeaders'>HC And er...</p>
                    <ul className='healthList'>
                        <li>Interaktivt adfærdsdesign, der inspirer og oplyser</li>
                        <li>Skræddersyet app, touchskærme i børnehøjde, diplomer og mestringsgaver</li>
                        <li>Bliver løbende afstemt med de gældende kliniske retningslinjer</li>
                        <li>Dokumenteret effekt. Har fx nedsat ventetiden på MR‐scanning fra 12 uger til 0</li>
                        <li>Er eksporteret til Sverige, Norge og på vej ud på flere internationale markeder</li>
                    </ul>
                </div>

                <div className='undersideColumn'>
                    <p className='healthCitat'>”Da Jonas skulle have lavet EEG brugte vi HC And. Jonas var så godt forberedt, at han som det første spurgte
                        sygeplejersken om hvor gavekassen var? Ud over det, hoppede han frisk op på briksen og fik ledningerne på, og han var helt med på det,
                        og ingen problemer. Vi havde også øvet os med HC And i at blinke hurtigt og dreje øjnene fra side til side, så det havde han styr på.
                        HC And hjælper Jonas på en helt særlig måde. Han forstår nemmere ved at se frem for at høre. Derfor forstår han meget mere af informationen
                        når han ser HC And blive bedøvet eller få lavet EEG, end hvis han får det fortalt.” ‐ Christine</p>
                    <p className='healthLinksHeader'>Links:</p>
                    <a className='healthLink' href="https://hcand.dk/" target="_blank" rel='noreferrer'>HC Ands hjemmeside</a><br />
                    <a className='healthLink' href="https://apps.apple.com/dk/developer/10%E2%80%9030%E2%80%90dk%E2%80%90aps/id1090350550#see-all/i-phonei-pad-apps"
                        target="_blank" rel='noreferrer'>Apps I App Store</a><br />
                    <a className='healthLink' href="https://play.google.com/store/apps/developer?id=1030.dk+ApS" target="_blank" rel='noreferrer'>Apps I Google Play</a><br />
                </div>
            </div>

            <div className='undersideColumnContainer'>
                <div className='healthImgWrapper'>
                    <div className='undersideColumn willHide'>
                        <img className='healthImg1 noPointerEvents' alt="Healthbillede - HC And giver mulighed for at blive forberedt på en sjov og interaktiv måde på en tablet" src={Health1} />
                        <p className='healthImg1 healthImgExplainerText'>HC And interaktive historier er udviklet til målgruppens foretrukne platform. Børnene kan 
                        forberede sig i små doser og i takt med deres udrednings- og behandlingsforløb.</p>
                    </div>

                    <div className='undersideColumn willHide'>
                        <img className='healthImg2 noPointerEvents' alt="Healthbillede - en touchskærm i øjenhøjde tiltrækker nemt opmærksomheden, og forbereder børnene på hvad der skal ske" src={Health2} />
                        <p className='healthImg2 healthImgExplainerText'>En HC And touch-skærm i børnehøjde har en magnetisk tiltrækning. Indhold på skærmene kan skræddersys 
                        med relevant indhold efter hospitalsafdelingens ønske og speciale.</p>
                    </div>

                    <div className='undersideColumn willHide'>
                        <img className='healthImg3 noPointerEvents' alt="Healthbillede - aktivitetshæfter, der kan lette på den nervøse tid i venteværelset" src={Health3} />
                        <p className='healthImg3 healthImgExplainerText'>Mestringsgave med flere funktioner. Aktivitetshæftet giver fx god mening til undersøgelser med 
                        tidsintervaller. Det kan give tryghed og "ro på" ved ventetid generelt.</p>
                    </div>
                </div>

                <div className='healthCarouselWrapper'>
                    <div className="healthCarouselPrev" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='healthPrevBar1'></div>
                        <div className='healthPrevBar2'></div>
                    </div>
                    <div className="healthCarouselNext" onClick={(e) => { e.stopPropagation(); }}>
                        <div className='healthNextBar1'></div>
                        <div className='healthNextBar2'></div>
                    </div>

                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={1}
                        speed={500}
                        loop={true}
                        navigation={{ prevEl: '.healthCarouselPrev', nextEl: '.healthCarouselNext' }}
                        breakpoints={{
                            640: { slidesPerView: 2, spaceBetween: 10 }
                        }}
                    >
                        <SwiperSlide className='healthCarouselAlignment'>
                            <img src={Health1} className='healthSliderImg noPointerEvents' alt="Healthbillede - HC And giver mulighed for at blive forberedt på en sjov og interaktiv måde på en tablet" />
                            <p className='healthImgExplainerText healthImgSwiperTextMargins'>HC And interaktive historier er udviklet til målgruppens foretrukne platform. 
                            Børnene kan forberede sig i små doser og i takt med deres udrednings- og behandlingsforløb.</p>
                        </SwiperSlide>
                        <SwiperSlide className='healthCarouselAlignment'>
                            <img src={Health2} className='healthSliderImg noPointerEvents' alt="Healthbillede - en touchskærm i øjenhøjde tiltrækker opmærksomheden, og forbereder børnene på hvad der skal ske" />
                            <p className='healthImgExplainerText healthImgSwiperTextMargins'>En HC And touch-skærm i børnehøjde har en magnetisk tiltrækning. 
                            Indhold på skærmene kan skræddersys med relevant indhold efter hospitalsafdelingens ønske og speciale.</p>
                        </SwiperSlide>
                        <SwiperSlide className='healthCarouselAlignment'>
                            <img src={Health3} className='healthSliderImg noPointerEvents' alt="Healthbillede - aktivitetshæfter, der kan lette på den nervøse tid i venteværelset" />
                            <p className='healthImgExplainerText healthImgSwiperTextMargins'>Mestringsgave med flere funktioner. Aktivitetshæftet giver fx god mening 
                            til undersøgelser med tidsintervaller. Det kan give tryghed og "ro på" ved ventetid generelt.</p>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <p className='kontaktTekst'>
                Overvejer I at lægge jeres information om til kommunikation, der løfter mestring?<br></br>
                Så send en mail til kontakt@1030.dk eller ring til Susanne på 8813 1030
                </p>

            </div>


        </>
    )
}

export default Health;