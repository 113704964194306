import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './Router';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';

// defining the root div
const root = ReactDOM.createRoot(document.getElementById('root'));

// rendering stuff within the root
root.render(
  // STRICTMODE CAUSES COMPONENTS TO RENDER TWICE IN PRODUCTION; NOT IN DEPLOYMENT
  // (they don't show twice, but useEffect will run twice)
  // <React.StrictMode>
    <CookiesProvider>
    <Router />
    </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
