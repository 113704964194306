import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {

    useEffect(() => {
        // auto-update the year of copyright
        let today = new Date();
        document.getElementById('copyrightYear').innerHTML = today.getFullYear();
    }, []);

    return (
        <>
        <div className='footerExtension'></div>
        <div className='footerBaggrund'>

            <div className='footerColumn'>
                <p className='footerHeader overskrift'>SKRIV, RING ELLER BESØG OS</p>
                <p className='footerText'>
                    kontakt@1030.dk <br />
                    +45 88 13 10 30 <br />
                    <br />
                    10:30 Visuel Kommunikation ApS <br/>
                    Sanderumvej 16B <br/>
                    DK-5250 Odense SV <br/>
                    <a className='footerLink' href='https://www.google.com/maps/place/Sanderumvej+16b,+5250+Odense' target="_blank" rel='noreferrer noopener'>Se på kort</a>
                </p>
            </div>
            <div className='footerColumn'>
                <p className='footerHeader overskrift'>NAVIGATION</p>
                <Link to="/health" className='footerMenu'>HEALTH</Link>
                <Link to="/adfaerdsdesign" className='footerMenu'>ADFÆRDSDESIGN</Link>
                <Link to="/nature" className='footerMenu'>NATURE</Link>
                <Link to="/day2day" className='footerMenu'>DAY2DAY</Link>
                <Link to="/coproduction" className='footerMenu'>CO-PRODUCTION</Link>
                <Link to="/omos" className='footerMenu'>OM OS</Link>
                <Link to="/privatlivspolitik" className='footerMenu'>COOKIE- OG PRIVATLIVSPOLITIK</Link>
            </div>
            <div className='footerColumn'>
                <p className='footerHeader overskrift'>FØLG OS</p>
                <a href="https://www.instagram.com/1030_visuel_kommunikation/" className="fa fa-instagram" target="_blank" rel="noreferrer noopener"></a>
                {/*<a href="https://www.linkedin.com/company/10-30-visuel-kommunikation" className="fa fa-linkedin" target="_blank" rel="noreferrer noopener"></a>*/}
            </div>
            <div className='footerColumn'>
                <p className='footerCitatText overskrift'>"ÈN GANG SET, HUSKES BEDRE END 100 GANGE HØRT"</p>
            </div>

            <p className='footerCopyright'>&copy; COPYRIGHT <p className='footerCopyright' id='copyrightYear'></p> | 10:30 VISUEL KOMMUNIKATION</p>
        </div>
        </>
    )
}

export default Footer;