import './Medarbejdere.css';
import MedarbBillede from './MedarbBillede';
import thorkild_inactive from "./Portrætter/thorkild-prikker_ver2a.png";
import thorkild_active from "./Portrætter/thorkild-prikker_ver2b.png";
import susanne_inactive from "./Portrætter/susanne-prikker_ver2a.png";
import susanne_active from "./Portrætter/susanne-prikker_ver2b.png";
import damian_inactive from "./Portrætter/damian_prikker_ver2a.png";
import damian_active from "./Portrætter/damian_prikker_ver2b.png";


function Medarbejdere() {
    return(
        <div className="medarbWrapper">
        <div className="medarbColumns">
        <MedarbBillede active={thorkild_active} inactive={thorkild_inactive} />
        <p className="medarbNavne overskrift">Thorkild<span style={{color: "#ee8939"}}>:</span>Havmøller</p>
        <p className="beskrivelse">Illustrator/animator<br/>Multimediedesigner<br/>Send en mail til</p>
        <p className="mail">th@1030.dk</p>
        </div>
        
        <div className="medarbColumns">
        <MedarbBillede active={susanne_active} inactive={susanne_inactive} />
        <p className="medarbNavne overskrift">Susanne<span style={{color: "#ee8939"}}>:</span>Bjerre</p>
        <p className="beskrivelse">Adm. direktør AD<br/>Multimediedesigner<br/>Send en mail til</p>
        <p className="mail">sb@1030.dk</p>
        </div>
        
        <div className="medarbColumns">
        <MedarbBillede active={damian_active} inactive={damian_inactive} />
        <p className="medarbNavne overskrift">Damian<span style={{color: "#ee8939"}}>:</span>Jørgensen</p>
        <p className="beskrivelse">Programmør<br/>Systemudvikler<br/>Send en mail til</p>
        <p className="mail">damian@1030.dk</p>
        </div>
        </div>
    )
}

export default Medarbejdere;