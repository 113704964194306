import { useEffect } from 'react';
import './BackToTop.css';


function BackToTop() {
    function scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function showButton() {
        let btn = document.getElementById("scrollToTopBtn");

        if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
            btn.style.opacity = "0.85";
            btn.style.zIndex = "10";
        } else {
            btn.style.opacity = "0";
            btn.style.zIndex = "-1";    // z-index ALSO transitions from 10 to -1 over 1s, so the button is technically clickable for a while after starting to fade out
        }
    }

    useEffect(
        () => {
            window.onscroll = () => { showButton() };
        }, []);

    return (
        <div>
            <button onClick={scrollToTop} id='scrollToTopBtn' title='Tilbage til toppen af siden'>&#9650;</button>
        </div>
    )
}

export default BackToTop;