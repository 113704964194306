import './UndersideBanner.css';
import { Link } from 'react-router-dom';


function UndersideBanner() {
    return(
        <div className='undersideBannerBaggrund'>
        <Link to="/health" className='undersideBannerColumn'>
        <p className='undersideBannerHeader overskrift'>10:30 HEALTH</p>
        <p className='undersideBannerText'>
        Når personer bliver patienter, har de brug for at blive taget i hånden – også visuelt. 
        Vi hjælper hospitaler med adfærdsdesign, der guider patienter til det gode undersøgelses‐ og indlæggelsesforløb.
        </p>
        </Link>

        <Link to="/adfaerdsdesign" className='undersideBannerColumn'>
        <p className='undersideBannerHeader overskrift'>10:30 ADFÆRDSDESIGN</p>
        <p className='undersideBannerText'>
        I institutioner har borgere og patienter af og til brug for at finde vej, finde hen eller finde ro. 
        Vi laver grafisk kommunikation, der inspirerer til den ønskede adfærd.
        </p>
        </Link>

        <Link to="/nature" className='undersideBannerColumn'>
        <p className='undersideBannerHeader overskrift'>10:30 NATURE</p>
        <p className='undersideBannerText'>
        Naturgenoprettelsesprojekter er svære at se for sig i projektfasen. 
        Vi skaber 3D‐visualisering, så tegnebrættet bliver levende og beslutningsprocessen lettere.
        </p>
        </Link>

        <Link to="/day2day" className='undersideBannerColumn'>
        <p className='undersideBannerHeader overskrift'>10:30 DAY2DAY</p>
        <p className='undersideBannerText'>
        Sommetider har man brug for grafisk kommunikation i en fart. 
        Vi hjælper med illustrationer, animationer, brochurer, plakater, bannere og alt fra skuffen med grafisk design fra dag til dag.
        </p>
        </Link>

        <Link to="/coproduction" className='undersideBannerColumn'>
        <p className='undersideBannerHeader overskrift'>10:30 CO-PRODUCTION</p>
        <p className='undersideBannerText'>
        Vi skaber produkter sammen med jer, der kommer på gode ideer til 
        apps, spil, AR eller VR, men ikke selv har grafisk‐ eller programmeringskapacitet i virksomheden til at føre projektet ud i livet.
        </p>
        </Link>
        </div>
    )
}

export default UndersideBanner;