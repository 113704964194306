import './OmOs.css'
import Medarbejdere from './Medarbejdere.js';


function OmOs() {
    return(
        <>
        <div className="ooBgcolor">
            <p className="ooHeading overskrift">OM 10:30</p>
            <div className="ooColumns">
            <p className="ooText">
Indsigt, respekt og empati. Det er de værdier vi tager med ud til vores kunder, når vi hjælper dem med at løse visuelle kommunikationsopgaver.<br/><br/>
Vi tror på at alt kan visualiseres og at meget kan siges hurtigere, bedre og mere målrettet med billeder end med ord. <br/><br/>
Gennem mere end femten år har vi samarbejdet med private virksomheder og offentlige organisationer om alt fra traditionelle visitkort over appudvikling til kompliceret wayfinding og nudging. <br/>
Vi hjælper vores kunder med at at holde fokus. Med at være nysgerrige på nye måder at sige det samme. Eller andre måder at sige noget nyt. <br/><br/>
Hos 10.30 får man kreativ kommunikation, grafisk håndværk og nærværende support & service. Udover os, får du også adgang til et hold af håndplukkede specialister og samarbejdspartnere, vi bruger til at løse opgaver. Alt hos 10.30 bliver nemlig løst med fokus på solid viden både om sagen og om målgruppen, der skal påvirkes positivt af kommunikationen.
            </p>
            </div>
        </div>

        <Medarbejdere />
        </>
    )
}

export default OmOs;