import Cookiebanner from "./Cookiebanner.js";
import ChangeCookies from "./ChangeCookies.js";
import { useCookies } from 'react-cookie';

function Cookies() {
    const [cookies] = useCookies(["cookieConsent"]);

    // checker om der er sat cookies - hvis ikke, så er det første besøg på siden, og vi skal spørge omkring cookiepræferencer
    // ellers vises knappen til at ændre disse præferencer i stedet
    function checkCookies(withCookies, noCookies) {
        if (cookies.cookieConsent !== undefined) {
            // if the cookie is already set, return the change cookies icon to be shown
            return withCookies;
        } else {
            // otherwise show the cookiebanner to inform
            return noCookies;
        }
    }

    // check for cookies, and render the correct cookie-component
    // removing and setting the cookie seems to automatically trigger a re-render of the component, so no page reloads necessary to redo this check
    return (
        <>
            {checkCookies(<ChangeCookies />, <Cookiebanner />)}
        </>
    )
}

export default Cookies;