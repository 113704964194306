import HeaderSlider from './HeaderSlider/HeaderSlider.js';
import UndersideBanner from './UndersideBanner/UndersideBanner.js';
import LøsningerRude from './LøsningerRude/LøsningerRude.js';
import Kunder from './Kunder/Kunder.js';



function Home() {
    return(
        <>       
        <HeaderSlider />
        <UndersideBanner />
        <LøsningerRude />
        <Kunder />
        </>
    )
}

export default Home;