import { useEffect } from 'react';
import './MedarbBillede.css';

function MedarbBillede(props) {
    useEffect(() => {
        // for these images, we cant disable all pointer-events, because that would break the mouseOver / mouseOut effects
        // therefore we instead prevent the right click menu from opening
        let images = document.getElementsByClassName('medarbImg');

        for (let i = 0; i < images.length; i++) {
            images[i].addEventListener("contextmenu", (e) => { e.preventDefault() });
        }
    }, []);

    return (
        <>
            <img className='medarbImg' src={props.inactive} alt="Medarbejderbillede"
                onMouseOver={e => (e.currentTarget.src = props.active)}
                onMouseOut={e => (e.currentTarget.src = props.inactive)} />
        </>
    )
}

export default MedarbBillede;