import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HeaderSlider.css';
import natureSlide from './SliderBilleder/nature.jpg';
import healthSlide from './SliderBilleder/health.jpg';
import adfærdSlide from './SliderBilleder/adfaerdsdesign.jpg';
import coprodSlide from './SliderBilleder/co_production.jpg';
import d2dSlide from './SliderBilleder/day2day.jpg';
// there might be a possibility to dynamically import all the pictures from the folder, and create slides from them generically
// see: https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack


function HeaderSlider() {
    let slideIndex = 0;
    let intervalID = 0;

    function setCurrentSlide(n) {
        // we changed slide manually, so we need to reset the interval with which the slides are changing automatically
        clearInterval(intervalID);
        slideIndex = n - 1;
        showSlide();
        intervalID = setInterval(showSlide, 4000);
    }

    function showSlide() {
        let slides = document.getElementsByClassName("headerSlide");
        let dots = document.getElementsByClassName("paginationDot");

        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (let i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" activeDot", "");
        }

        slideIndex++;
        if (slideIndex > slides.length) {
            slideIndex = 1;
        }

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " activeDot";
    }

    function createDots() {
        let slides = document.getElementsByClassName("headerSlide");

        for (let i = 0; i < slides.length; i++) {
            let span = document.createElement('span');
            span.addEventListener('click', () => { setCurrentSlide(i + 1) });
            span.classList.add("paginationDot");

            document.getElementById("headerPagination").appendChild(span);
        }
    }

    // gets called when the component is mounted
    useEffect(
        () => {
            createDots();
            showSlide();
            intervalID = setInterval(showSlide, 4000);

            // since we have to be able to click the images to use them to navigate, we cant disable all pointer events for these
            // instead, we protect them from saving by disabling the right click menu on them
            let headerSliders = document.getElementsByClassName('headerSlideBillede');
            for (let i = 0; i < headerSliders.length; i++) {
                headerSliders[i].addEventListener("contextmenu", (e) => { e.preventDefault() });
            }

            // this return function is called when the component unmounts - ie when routing away from the Home page
            return () => {
                clearInterval(intervalID);
            };
        }, []);     // this empty array means useEffect is only called on component mount
    // the array can be used to indicate things that should be 'listened' to - so that when there is an update in something in the array, useEffect is called again

    return (
        <div className="slideshowContainer">

            {/* HEALTH SLIDE */}
            <Link className='headerSliderLinks' to='/health'>
                <div className="headerSlide">
                    <img className='headerSlideBillede fade' src={(healthSlide)} alt="Forsidebillede af børn, der ved hjælp af HC And kan lære og blive forberedt på medicinske undersøgelser på en sjov og interaktiv måde"></img>
                    <div className="headerSliderCaption slowFade overskrift" id='healthSliderCaption'>10:30 HEALTH</div>
                </div>
            </Link>

            {/* ADFÆRD SLIDE */}
            <Link className='headerSliderLinks' to='/adfaerdsdesign'>
                <div className="headerSlide">
                    <img className='headerSlideBillede fade' src={(adfærdSlide)} alt="Forsidebillede af adfærdsdesign, der viser hvordan designdetaljer kan ændre udtrykket og puste liv i et rum"></img>
                    <div className="headerSliderCaption slowFade overskrift" id='adfærdSliderCaption'>10:30 ADFÆRDSDESIGN</div>
                </div>
            </Link>

            {/* NATURE SLIDE */}
            <Link className='headerSliderLinks' to='/nature'>
                <div className="headerSlide">
                    <img className='headerSlideBillede fade' src={(natureSlide)} alt="Forsidebillede af naturgenopretningsprojekt i 3D animation"></img>
                    <div className="headerSliderCaption slowFade overskrift" id='natureSliderCaption'>10:30 NATURE</div>
                </div>
            </Link>

            {/* DAY2DAY SLIDE */}
            <Link className='headerSliderLinks' to='/day2day'>
                <div className="headerSlide">
                    <img className='headerSlideBillede fade' src={d2dSlide} alt="Forsidebillede til day2day - stedet i 10:30, hvor vi hjælper dig med dine grafiske opgaver i dagligdagen"></img>
                    <div className="headerSliderCaption slowFade overskrift" id='d2dSliderCaption'>10:30 DAY2DAY</div>
                </div>
            </Link>

            {/* COPRODUCTION SLIDE */}
            <Link className='headerSliderLinks' to='/coproduction'>
                <div className="headerSlide">
                    <img className='headerSlideBillede fade' src={coprodSlide} alt="Forsidebillede til co-production - vi hjælper dig med at komme fra idé til fuldendt projekt"></img>
                    <div className="headerSliderCaption slowFade overskrift" id='coprodSliderCaption'>10:30 CO-PRODUCTION</div>
                </div>
            </Link>


            {/* PAGINATION DOTS */}
            <div id='headerPagination' style={{ "textAlign": "center" }}>
                {/* has been moved to be generically generated in useEffect, when component mounts */}
                {/* <span className='paginationDot' onClick={() => { setCurrentSlide(1) }}></span> */}
            </div>

        </div>
    )
}

export default HeaderSlider;