import './Router.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import Health from './Undersider/Health/Health.js';
import Adfærd from './Undersider/Adfærd/Adfærd.js';
import Nature from './Undersider/Nature/Nature.js';
import Day2day from './Undersider/Day2day/Day2day.js';
import Coprod from './Undersider/Coproduction/Coprod.js';
import FlyoutMenu from './SharedComponents/FlyoutMenu/FlyoutMenu.js';
import Footer from './SharedComponents/Footer/Footer.js';
import Logo from './SharedComponents/Logo/Logo.js';
import ScrollToTop from './scrollToTop.js';
import BackToTop from './SharedComponents/BackToTop/BackToTop.js';
import OmOs from './Undersider/OmOs/OmOs.js';
import Cookies from './SharedComponents/Cookiebanner/Cookies.js';
import Error404 from './Undersider/404/404.js';
import Privatlivspolitik from './SharedComponents/Cookiebanner/Privatlivspolitik/Privatlivspolitik.js';


// this is a function that returns some HTML to be rendered
// it is called a React component, and must start with uppercase
function Router() {
    return (
        // in here we can write straight HTML, because of JSX
        // (which means "class" is a reserved keyword for js, so use the full className instead)
        // we can also embed javascript expressions inside the JSX, as long as it is wrapped in curly braces
        // there must only be one top-level element though, so if there are multiple elements, put them in a wrapping fragment (which avoids adding unnecessary nodes to DOM)
        <BrowserRouter>
        <ScrollToTop />
        <Cookies />
        <Logo />
        <FlyoutMenu />
        <Routes>
            <Route path="/">
                <Route index element={<Home />} />
                <Route path="health" element={<Health />} />
                <Route path="adfaerdsdesign" element={<Adfærd />} />
                <Route path="nature" element={<Nature />} />
                <Route path="day2day" element={<Day2day />} />
                <Route path="coproduction" element={<Coprod />} />
                <Route path="omos" element={<OmOs />} />
                <Route path="privatlivspolitik" element={<Privatlivspolitik />} />
                <Route path="*" element={<Error404 />} />
            </Route>
        </Routes>
        <BackToTop />
        <Footer />
        </BrowserRouter>
    )
}

// export this component, so we can import it in index.js and render it
export default Router;