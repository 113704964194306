import './Cookiebanner.css';
import { useCookies } from 'react-cookie';
import Privatlivspolitik from './Privatlivspolitik/Privatlivspolitik.js';
import { useEffect } from 'react';


function Cookiebanner() {
    const [cookies, setCookie] = useCookies(['cookieConsent']);

    function saveCookieSelection() {
        let expiration = 2592000;   // the cookie should expire a month after it is set - this is a months worth of seconds
        setCookie("cookieConsent", "necessary", { path: '/', maxAge: expiration, sameSite: 'strict' });
    }

    // toggles fold / unfold of privatlivspolitik
    function togglePrivacyPolicy() {
        let pripolContainer = document.getElementById('pripolUnfolder');
        let cookieBox = document.getElementsByClassName('cookieBox')[0];
        let cookieHeader = document.getElementsByClassName('cookieHeader')[0];
        let cookieText = document.getElementsByClassName('cookieText')[0];
        let cookieLinkText = document.getElementsByClassName('cookieText')[1];
        let pripolButton = document.getElementById('pripolFoldOutBtn');

        // if privatlivspolitik is already unfolded, hide it, and vice versa
        if (pripolContainer.style.maxHeight !== "50vh") {
            pripolContainer.style.maxHeight = "50vh";
            // after unfolding the privacy policy, we make it clear that the link can now be used to hide it again
            cookieLinkText.style.display = "none";
            pripolButton.style.display = "block";
        } else {
            pripolContainer.style.maxHeight = "0";
            cookieLinkText.style.display = "inline-block";
            pripolButton.style.display = "none";
        }
        // and toggle the unfolded class on the cookiebox, which causes it to become wider on smaller screens
        cookieBox.classList.toggle("cookiesUnfolded");

        // we also toggle a class on some of the text, so we can hide it to make sure the "OK" button is always visible (without scrolling)
        cookieHeader.classList.toggle("hideOnSmallHeight");
        cookieText.classList.toggle("hideOnSmallHeight");
    }

    useEffect(() => {
        // disable scrolling on the page behind the cookiebanner while it is active
        document.body.style.overflow = "hidden";

        // enable scrolling on the main page when the cookiebanner is no longer being shown
        return () => {
            document.body.style.overflow = "visible";
        }
    }, []);

    return (
        <div id='cookieBackground'>
            <div className='cookieBox'>
                <p className='cookieHeader'>Brug af cookies</p>
                <p className='cookieText'>Vi anvender en enkelt cookie, for at hjemmesiden kan huske at du har klikket "OK" på cookie-informationsbanneret.</p>
                <p className='cookieText'>Læs mere om vores <button className='privacyPolicyLink' onClick={() => { togglePrivacyPolicy(); }}>cookie- og privatlivspolitik.</button></p>
                <button className='privacyPolicyLink' id='pripolFoldOutBtn' onClick={() => { togglePrivacyPolicy(); }}>Klik her for at folde sammen igen.</button>
                <div id='pripolUnfolder'><Privatlivspolitik /></div>
                <button className='cookieButton' onClick={saveCookieSelection}>OK</button>
                <div className='switchBox'>
                    <div className='cookieSwitchColumn'>
                        <p className='cookieSwitchText'>Nødvendige</p>
                        <label className='cookieSwitch'>
                            <input type='checkbox' checked readOnly />
                            <span className='switchSlider'></span>
                        </label>
                    </div>
                    <div className='cookieSwitchColumn cookieSwitchHide'>
                        <p className='cookieSwitchText'>Funktionelle</p>
                        <label className='cookieSwitch'>
                            <input type='checkbox' disabled />
                            <span className='switchSlider'></span>
                        </label>
                    </div>
                    <div className='cookieSwitchColumn cookieSwitchHide'>
                        <p className='cookieSwitchText'>Statistik</p>
                        <label className='cookieSwitch'>
                            <input type='checkbox' disabled />
                            <span className='switchSlider'></span>
                        </label>
                    </div>
                    <div className='cookieSwitchColumn cookieSwitchHide'>
                        <p className='cookieSwitchText'>Marketing</p>
                        <label className='cookieSwitch'>
                            <input type='checkbox' disabled />
                            <span className='switchSlider'></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cookiebanner;