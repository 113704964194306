import './Logo.css';
import LogoImg from './logosmall.png';
import { Link } from 'react-router-dom';


function Logo() {
    // if the logo is clicked while already on the Home page, we want to scroll to the top of the page
    // since the location / pathname is not updated in that case, the normal scrollToTop.js trigger does not work
    function resetScroll() {
        window.scrollTo(0,0);
    }

    return(
        <>
        <Link to="/">
        <img className='topLogo' src={LogoImg} alt="10:30 Visuel Kommunikation logo" onClick={resetScroll} />
        </Link>
        </>
    )
}

export default Logo;