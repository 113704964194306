import './404.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Error404() {
    function moveBall() {

    }

    useEffect(() => {
        let time = new Date();
        let hours = time.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }
        let minutes = time.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        document.getElementById("404CurrentTime").innerHTML = "Klokken er nu " + hours + ":" + minutes
    }, []);

    return (
        <div className='e404background undersideColumnContainer'>
            <p className='e404text'>Fejl 404 - Siden kunne ikke findes!</p>
            <p className='digitalText' id='404CurrentTime'>Klokken er nu </p>
            <Link to="/" className="backTo1030Link">Vil du tilbage til 10:30?</Link> <br /><br /><br /><br />
        </div>
    );
}

export default Error404;